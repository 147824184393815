// Section CTA Styling
.cta {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;

  &--v1 {
    background-color: $color-extra-light-blue;
    overflow: hidden;
    padding-top: toRem(80);
    padding-bottom: toRem(224);
    position: relative;
    z-index: 1;

    .pattern {
      background-image: url('../../media/images/patterns-and-ornaments/cta-background-v1.svg');
      background-size: cover;
      background-position: center;
      position: absolute;
      width: 568px;
      height: 179px;
      left: toRem(-168);
      top: toRem(377);
      z-index: -1;

      @include media-breakpoint-down(md) {
        left: toRem(-16);
        top: toRem(64);
      }
    }
  }

  &--v2 {
    height: 800px;
    position: relative;
    overflow: hidden;

    padding-top: toRem(308);
    padding-bottom: toRem(286);
    z-index: 1;

    @include media-breakpoint-down(sm) {
      padding-top: toRem(140);
      padding-bottom: toRem(126);
    }

    .background {
      position: absolute;
      width: 100vw;
      height: 100%;
      background-color: $color-extra-light-blue;
      clip-path: polygon(0 220px, 100% 0, 100% 100%, 0 100%);
      z-index: -2;

      @include media-breakpoint-down(lg) {
        clip-path: polygon(0 100px, 100% 0, 100% 100%, 0% 100%);
      }

      @include media-breakpoint-down(sm) {
        clip-path: polygon(0 50px, 100% 0, 100% 100%, 0 100%);
      }
    }

    .pattern {
      background-image: url('../../media/images/patterns-and-ornaments/cta-background-v2.svg');
      background-size: cover;
      background-position: center;
      position: absolute;
      width: 657px;
      height: 630px;
      left: toRem(-168);
      top: toRem(144);
      z-index: -1;

      @include media-breakpoint-down(md) {
        left: toRem(-172);
        top: toRem(19);
      }
    }
  }

  &--v3,
  &--subs {
    background-color: $color-white;
    padding: toRem(80) 0;

    @include media-breakpoint-down(md) {
      padding: toRem(64) 0;
    }
  }

  &--v3 {
    @include media-breakpoint-down(sm) {
      padding: 0;
    }
  }

  &--mini {
    overflow: hidden;
    background-color: $color-extra-light-blue;
    padding: toRem(80) 0;

    @include media-breakpoint-down(md) {
      padding: toRem(64) 0;
    }

    @include media-breakpoint-down(sm) {
      padding-inline: toRem(20);
    }
  }
}

// Container CTA Styling
.container {
  --bs-gutter-x: 0;
  --bs-gutter-y: 0;
  display: flex;
  align-items: center;

  &--cta-v1 {
    flex-direction: column;
    width: 713px;
    text-align: center;

    @include media-breakpoint-down(sm) {
      padding-inline: toRem(20);
    }
  }

  &--cta-v2 {
    flex-direction: column;
    text-align: center;
    width: 713px;

    @include media-breakpoint-down(sm) {
      padding-inline: toRem(20);
    }
  }

  &--cta-v3 {
    padding: toRem(97) toRem(48);
    background-color: $color-extra-light-blue;
    gap: 85px;

    // Individual V3 CTA Styling
    .ornament {
      position: absolute;
      width: 1857px;
      height: 707px;
      top: toRem(-130);
      left: toRem(-91);
    }

    .cta-v3--left {
      @include media-breakpoint-down(sm) {
        text-align: center;
      }
    }

    .cta-v3--right {
      @include media-breakpoint-down(sm) {
        width: 100%;
      }
    }
  }

  &--cta-subs {
    padding: toRem(48);
    background-image: $gradient-background;
    overflow: hidden;
    gap: 64px;

    // Individual Subscribe CTA Styling
    .ornament {
      position: absolute;
      width: 1565px;
      height: 464.5px;
      top: toRem(-164);
      left: toRem(-99);
    }

    .cta-subs--left {
      @include media-breakpoint-down(sm) {
        text-align: center;
      }
    }

    .cta-subs--right {
      @include media-breakpoint-down(sm) {
        width: 100%;
      }
    }
  }

  &--cta-v3,
  &--cta-subs {
    overflow: hidden;
    position: relative;
    border-radius: 20px;
    width: 100%;

    @include media-breakpoint-down(xl) {
      flex-direction: column;
      text-align: center;
      gap: toRem(64);
    }

    @include media-breakpoint-down(sm) {
      border-radius: 0;
      flex-direction: column;
      padding: toRem(56) toRem(20);
      gap: toRem(32);
    }
  }

  &--cta-mini {
    display: flex;
    flex-direction: column;
    gap: toRem(32);
    position: relative;
    align-items: center;
    text-align: center;
    z-index: 1;

    @include media-breakpoint-down(sm) {
      gap: toRem(24);
    }

    .pattern {
      position: absolute;
      width: 2297px;
      height: 684px;
      top: toRem(-194);
      left: toRem(-267);
      background-image: url('../../media/images/patterns-and-ornaments/cta-background-mini.svg');
      background-position: center;
      background-size: contain;
      z-index: -1;
    }

    .text-wrap {
      display: flex;
      flex-direction: column;
      gap: toRem(16);
      max-width: 677px;
      align-items: center;

      @include media-breakpoint-down(sm) {
        gap: toRem(8);
        max-width: 100%;
      }
    }

    .btn-wrap {
      display: flex;
      flex-direction: row;
      gap: toRem(16);
      align-items: center;

      @include media-breakpoint-down(sm) {
        flex-direction: column;
        gap: toRem(8);
        width: 100%;

        & > * {
          width: 100%;
        }
      }
    }
  }
}

// CTA Title Styling
.cta-title {
  //   Use h2 for title cta
  &--v1,
  &--v2,
  &--v3,
  &--mini {
    color: $color-dark-deep-blue;
  }

  &--subs {
    color: $color-white;
  }
}

// CTA Paragraph Styling
.cta-para {
  @include text-shorten($line: 2);
  //   Use class text-l-regular for paragraph
  &--v1 {
    max-width: 462px;
    margin-top: toRem(21);
    margin-bottom: toRem(42);
  }

  &--v2 {
    max-width: 682px;
    margin-top: toRem(21);
    margin-bottom: toRem(42);

    @include media-breakpoint-down(sm) {
      margin-top: toRem(10);
      margin-bottom: toRem(21);
    }
  }

  &--v3 {
    margin-top: toRem(26);

    @include media-breakpoint-down(sm) {
      margin-top: toRem(10);
    }
  }

  &--subs {
    color: $color-white;
    margin-top: toRem(21);
    padding-right: toRem(58);

    @include media-breakpoint-down(xl) {
      padding-right: 0;
    }

    @include media-breakpoint-down(sm) {
      padding: 0;
      margin-top: toRem(10);
      margin-bottom: toRem(27);
    }
  }

  &--mini {
    max-width: 462px;
  }
}

// Button Container for V1 CTA
.cta-btn-container {
  &--v1 {
    z-index: 2;
    display: flex;
    flex-direction: row;
    gap: 24px;

    @include media-breakpoint-down(sm) {
      width: 100%;
      flex-direction: column;
      align-items: stretch;
      gap: 12px;
    }
  }
}

// Input form styling
.form-search__input--inp.cta-form-search-input {
  @include media-breakpoint-down(sm) {
    padding: toRem(16) toRem(24);
  }
}
